export function loggedOutEditNotificationResourceDefinition(buyerId) {
  return {
    url: `/api/buyer_profiles/${buyerId}/logged_out_notification_settings/edit.json`,
    defaultValue: {
      meta: {},
      buyer_notification_setting: {
        community_updates: false
      }
    }
  };
}

export function loggedOutUpdateNotificationResourceDefinition(buyerId) {
  return {
    method: 'PUT',
    url: `/api/buyer_profiles/${buyerId}/logged_out_notification_settings.json`
  };
}

export const createUserMutationDefinition = {
  method: 'POST',
  url: '/api/users.json'
};

export function buyerProfileUpdateMutationDefinition(buyerId) {
  return {
    method: 'PUT',
    url: `/api/buyer_profiles/${buyerId}.json`
  };
}

export function buyerProfileEditResourceDefinition(buyerId) {
  return {
    url: `/api/buyer_profiles/${buyerId}/edit.json`,
    defaultValue: {
      buyer_profile: {
        user_attributes: {
          phone_numbers_attributes: []
        },
        address_attributes: {}
      }
    },
    enabled: Boolean(buyerId)
  };
}
