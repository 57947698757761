import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Logo from 'dpl/components/Logo';
import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';
import QuizProgressBar from 'dpl/components/Quiz/QuizProgressBar';

export function QuizQuestion({ children }) {
  return <div className="QuizQuestion">{children}</div>;
}

QuizQuestion.propTypes = {
  children: PropTypes.node,
  /* eslint-disable react/no-unused-prop-types */
  skippable: PropTypes.bool,
  answered: PropTypes.bool,
  forceDisplay: PropTypes.bool
  /* eslint-enable react/no-unused-prop-types */
};

QuizQuestion.defaultProps = {
  children: null,
  skippable: false,
  answered: false,
  forceDisplay: null
};

export function QuizHeader({ onSaveClick, text, onCancelClick }) {
  return (
    <div className="QuizHeader" data-test-id="QuizHeader">
      <div className="bg-black h-100 flex white items-center">
        <div className="ph6">
          <div className="Header__logo">
            <Logo />
          </div>
        </div>
        <div className="d-md-block d-none">{text}</div>
        <button
          type="button"
          className="br0 ph6 ttu ml-auto"
          onClick={onCancelClick}
        >
          Cancel
        </button>
        {typeof onSaveClick === 'function' && (
          <button
            type="button"
            className="button button--primary br0 ph6 ttu self-stretch"
            onClick={onSaveClick}
          >
            Save &amp; Exit
          </button>
        )}
      </div>
    </div>
  );
}

QuizHeader.propTypes = {
  text: PropTypes.string.isRequired,
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func.isRequired
};

QuizHeader.defaultProps = {
  onSaveClick: null
};

export function QuizContent({
  currentQuestionIdx,
  children,
  onTransitionEnd,
  containerClassName,
  questionClassName,
  centerChildren,
  isTransitioning
}) {
  return (
    <div className="QuizContent">
      <div className={classnames('relative', containerClassName)}>
        {children.map((child, idx) => {
          if (Math.abs(currentQuestionIdx - idx) > 1) {
            return null;
          }

          const isCurrentQuestion = currentQuestionIdx === idx;

          return (
            <div
              key={idx}
              onTransitionEnd={
                isCurrentQuestion
                  ? e => {
                      e.target === e.currentTarget && onTransitionEnd(e);
                    }
                  : null
              }
              style={{
                transform: `translate3d(${
                  (idx - currentQuestionIdx) * 100
                }%, 0px, 0px)`
              }}
              className={classnames(
                'Quiz__questionPageContainer mw-100',
                questionClassName,
                {
                  'absolute absolute--fill overflow-hidden': !isCurrentQuestion,
                  'v-hidden': !isCurrentQuestion && !isTransitioning
                }
              )}
            >
              <div
                className={classnames('h-100 flex justify-center', {
                  'items-center': centerChildren
                })}
              >
                <div className="w-100">{child}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

QuizContent.propTypes = {
  currentQuestionIdx: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  onTransitionEnd: PropTypes.func,
  containerClassName: PropTypes.string,
  questionClassName: PropTypes.string,
  centerChildren: PropTypes.bool,
  isTransitioning: PropTypes.bool
};

QuizContent.defaultProps = {
  onTransitionEnd: null,
  containerClassName: null,
  questionClassName: null,
  centerChildren: true,
  isTransitioning: false
};

export default function Quiz({
  children,
  onNextClick,
  onPreviousClick,
  onDoneClick,
  onSaveClick,
  onCancelClick,
  headerText,
  currentQuestionIdx,
  setScrollableRef,
  canSubmit,
  trackStepChange
}) {
  const validChildren = children.filter(Boolean);
  const validQuestionIdx = Math.min(
    currentQuestionIdx,
    validChildren.length - 1
  );
  const { skippable, answered, questionName } =
    validChildren[validQuestionIdx].props;
  function handleNextClick() {
    if (trackStepChange) {
      trackStepChange(questionName);
    }
    onNextClick();
  }
  return (
    <div className="Quiz">
      <div className="bg-near-white absolute--fill fixed flex flex-column">
        <QuizHeader
          text={headerText}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
        />
        <div className="flex-auto h-100 overflow-hidden">
          <div
            className="h-100 transition"
            style={{ transform: `translateY(-${validQuestionIdx * 100}%)` }}
          >
            {validChildren.map((child, idx) => (
              <FixedScrollWrapper
                key={idx}
                className="pv16-md pv8 overflow-x-hidden h-100"
                setRef={
                  currentQuestionIdx === idx ? setScrollableRef : () => {}
                }
              >
                <div className="Quiz__questionContainer ph6 h-100 center">
                  {Math.abs(currentQuestionIdx - idx) < 2 && child}
                </div>
              </FixedScrollWrapper>
            ))}
          </div>
        </div>
        <div className="Quiz__progressBarContainer ph6 center w-100">
          <QuizProgressBar
            stepCount={validChildren.length}
            currentStep={validQuestionIdx + 1}
            onNextClick={handleNextClick}
            onPreviousClick={onPreviousClick}
            onDoneClick={onDoneClick}
            isNextDisabled={(!answered && !skippable) || !canSubmit}
            nextStepText={skippable && !answered ? 'Skip' : 'Next'}
          />
        </div>
      </div>
    </div>
  );
}

Quiz.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ type: PropTypes.oneOf([QuizQuestion]).isRequired }),
      PropTypes.bool
    ])
  ).isRequired,
  currentQuestionIdx: PropTypes.number,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  setScrollableRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  canSubmit: PropTypes.bool,
  trackStepChange: PropTypes.func
};

Quiz.defaultProps = {
  currentQuestionIdx: 0,
  onSaveClick: null,
  setScrollableRef: null,
  canSubmit: true,
  trackStepChange: null
};
