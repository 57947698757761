import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function withIndex(WrappedComponent) {
  return class IndexComponent extends Component {
    static propTypes = {
      initialIndex: PropTypes.number
    };

    static defaultProps = {
      initialIndex: 0
    };

    state = { idx: this.props.initialIndex };

    increment = cb => this.setIndex(this.state.idx + 1, cb);
    decrement = cb => this.setIndex(this.state.idx - 1, cb);
    setIndex = (idx, cb) =>
      this.setState({ idx }, () => typeof cb === 'function' && cb());

    render() {
      const { idx } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          decrement={this.decrement}
          increment={this.increment}
          index={idx}
          setIndex={this.setIndex}
        />
      );
    }
  };
}
