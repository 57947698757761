import { GENDERS } from 'dpl/shared/constants/breeder_app';
import { LITTER_STATES } from 'dpl/shared/constants/shared';
import { PAYMENTS_SETUP_CALLOUT_KEY } from 'dpl/shared/constants/localStorage';
import { setLocalStorageValue } from 'dpl/shared/utils/localStorage';

export function getLitterParentNamesText(litter) {
  return `${litter.name}'s ${litter.go_home_date} Litter`;
}

export function getOrderedObjectEntries(obj, orderedKeys) {
  return [
    ...new Map([...orderedKeys.map(t => [t]), ...(Object.entries(obj) || [])])
  ];
}

export function getParentIdxByGender(parents, gender) {
  return parents.findIndex(parent => parent.dog_attributes.gender === gender);
}

export function getOrderedParents(parents) {
  return [...parents].sort(parent => (parent.gender === GENDERS.MALE ? -1 : 1));
}

export function nameUnnamedPuppies(puppies) {
  let girlCount = 1;
  let boyCount = 1;

  function createPuppyName(puppy) {
    if (puppy.gender === GENDERS.MALE) {
      return `Boy ${boyCount++}`;
    }

    if (puppy.gender === GENDERS.FEMALE) {
      return `Girl ${girlCount++}`;
    }

    return 'TBD';
  }

  return puppies.map(puppy => {
    if (!puppy.name) {
      puppy.name = createPuppyName(puppy);

      // Prevent duplicated temp puppy names
      while (
        puppies.some(p => p.tempId !== puppy.tempId && p.name === puppy.name)
      ) {
        puppy.name = createPuppyName(puppy);
      }
    }

    return puppy;
  });
}

export function getLitterStatusText(litterStatus, formattedBirthDate) {
  const statusText = {
    [LITTER_STATES.PLANNED]: 'Planned for',
    [LITTER_STATES.EXPECTED]: 'Expected',
    [LITTER_STATES.BORN]: 'Born',
    [LITTER_STATES.ARCHIVED]: 'Archived'
  }[litterStatus];

  return statusText && `${statusText} ${formattedBirthDate}`;
}

export function getLitterStatusIconName(litterStatus) {
  switch (litterStatus) {
    case LITTER_STATES.EXPECTED:
      return 'fetch-clock';
    case LITTER_STATES.PLANNED:
      return 'fetch-calendar';
    default:
      return 'fetch-litter';
  }
}

export function getLitterEditPath(litter) {
  if (!litter.published) {
    return `/dashboard/litters/new/${litter.id}`;
  }
  return `/dashboard/litters/${litter.id}`;
}

export function setPaymentsSetupCalloutStorageValue() {
  setLocalStorageValue(PAYMENTS_SETUP_CALLOUT_KEY, true);
}

export function getCalendlyUserFields(user) {
  return {
    name: [user.first_name, user.last_name, user.name && `(${user.name})`]
      .filter(Boolean)
      .join(' '),
    email: user.email
  };
}

export function getPuppyPhotoReminderStatus(litter) {
  return {
    visible:
      litter.status === LITTER_STATES.BORN &&
      litter.available &&
      !litter.archived &&
      litter.puppies.some(p => !p.profile_photo_url)
  };
}
