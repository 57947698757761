import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isLessThanBreakpoint } from 'dpl/util/grid';
import FullPageQuizContext from 'dpl/components/FullPageQuiz/FullPageQuizContext';
import QuizIcon from 'dpl/quiz/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import QuizSubmitDisclaimer from 'dpl/adoption_application/components/QuizSubmitDisclaimer';

const IS_LT_LG = isLessThanBreakpoint('lg');
const IMAGE_WIDTH = IS_LT_LG ? '64px' : '96px';

export const FULL_PAGE_QUIZ_QUESTION_VARIANTS = {
  WIDE: 'wide',
  REGULAR: 'regular'
};

export default function FullPageQuizQuestion({
  title,
  subtitle,
  icon,
  children,
  className,
  stepIndex,
  subtitleClassName,
  isValid,
  onShown,
  onSubmit,
  handleNextClick,
  handleSkipClick,
  imageUrl,
  hideContinueBtn,
  hideSubmitBtn,
  continueBtnLabel,
  submitBtnLabel,
  submitBtnClassName,
  variant,
  isShown,
  showSkipBtn,
  newDesignSystemStyles
}) {
  const { fixedNav, isLoading } = useContext(FullPageQuizContext) || {};

  function handleFormSubmit(e) {
    e.preventDefault();
    if (isValid && handleNextClick) {
      handleNextClick();
    }
  }

  function onSkipClick() {
    if (handleSkipClick) {
      handleSkipClick();
    }
  }

  let buttonStyles;
  if (newDesignSystemStyles) {
    buttonStyles =
      'w-100 justify-center bg-royal-blue white b--royal-blue br2 ph4 fw-medium font-16';
  } else {
    buttonStyles = 'button button--primary button--large';
  }

  const continueBtn = !hideContinueBtn && (
    <button
      type="submit"
      disabled={!isValid || isLoading}
      className={classnames('pv3 mt4 mb2 inline-flex fw-medium', buttonStyles, {
        'button--loading': isLoading,
        'bg-surface-disabled': newDesignSystemStyles && !isValid
      })}
    >
      {continueBtnLabel}
    </button>
  );

  const submitBtn = !hideSubmitBtn && (
    <button
      type="button"
      onClick={onSubmit}
      disabled={!isValid || isLoading}
      className={classnames(
        'pv3 mt4 mb2 center',
        buttonStyles,
        {
          'button--loading': isLoading,
          'bg-surface-disabled': newDesignSystemStyles && !isValid
        },
        submitBtnClassName
      )}
    >
      {submitBtnLabel}
    </button>
  );

  useEffect(() => {
    if (isShown && stepIndex >= 0) onShown(stepIndex);
  }, [isShown]);

  return (
    <div
      className={classnames(
        'FullPageQuizQuestion pv4 ph4 center tc',
        className,
        {
          'FullPageQuizQuestion--wide':
            variant === FULL_PAGE_QUIZ_QUESTION_VARIANTS.WIDE
        }
      )}
    >
      <div className="mb10">
        {icon && (
          <div className="mb6 mb8-md f0">
            <QuizIcon name={icon} />
          </div>
        )}
        {imageUrl && (
          <div className="mb4">
            <SmartImage
              className="br-100"
              crop
              height={IMAGE_WIDTH}
              loadingClass="bg-light-gray"
              src={imageUrl}
              width={IMAGE_WIDTH}
            />
          </div>
        )}
        {title && !newDesignSystemStyles && (
          <p className="FullPageQuizQuestion__title f4 fw-medium f6-md">
            {title}
          </p>
        )}
        {title && newDesignSystemStyles && (
          <h3 className="FullPageQuizQuestion__title f4 f6-md lh-normal">
            {title}
          </h3>
        )}
        {subtitle && (
          <p
            className={classnames(
              'mt2 mt4-md font-16 secondary',
              subtitleClassName
            )}
          >
            {subtitle}
          </p>
        )}
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="mb4 mb14-md">{children}</div>
        {!fixedNav && (onSubmit ? submitBtn : continueBtn)}
        {showSkipBtn && (
          <button
            type="button"
            className="inline-flex fw-medium w-100 justify-center midnight font-16 pv2"
            onClick={onSkipClick}
          >
            Skip
          </button>
        )}
        {!fixedNav && onSubmit && (
          <div className="mt4 mt8-lg">
            <QuizSubmitDisclaimer />
          </div>
        )}
      </form>
    </div>
  );
}

FullPageQuizQuestion.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  stepIndex: PropTypes.number,
  subtitleClassName: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  handleNextClick: PropTypes.func,
  handleSkipClick: PropTypes.func,
  onShown: PropTypes.func,
  onSubmit: PropTypes.func,
  imageUrl: PropTypes.string,
  isShown: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(FULL_PAGE_QUIZ_QUESTION_VARIANTS)),
  hideContinueBtn: PropTypes.bool,
  hideSubmitBtn: PropTypes.bool,
  continueBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string,
  submitBtnClassName: PropTypes.string,
  newDesignSystemStyles: PropTypes.bool,
  showSkipBtn: PropTypes.bool
};

FullPageQuizQuestion.defaultProps = {
  title: null,
  subtitle: null,
  icon: null,
  className: null,
  stepIndex: null,
  subtitleClassName: null,
  onShown: () => {},
  onSubmit: null,
  handleNextClick: null,
  handleSkipClick: null,
  imageUrl: null,
  isShown: false,
  variant: FULL_PAGE_QUIZ_QUESTION_VARIANTS.REGULAR,
  hideContinueBtn: false,
  hideSubmitBtn: false,
  continueBtnLabel: 'Continue',
  submitBtnLabel: 'Submit your info',
  submitBtnClassName: null,
  newDesignSystemStyles: false,
  showSkipBtn: false
};
