import React from 'react';

import { TOS_PATH, PRIVACY_PATH } from 'dpl/shared/constants/urls';

export default function QuizSubmitDisclaimer() {
  return (
    <p className="AdoptionApplicationQuizSubmitDisclaimer f1 tertiary center tc">
      By continuing, you agree to Good Dog&apos;s&nbsp;
      <a
        className="underline"
        href={TOS_PATH}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
      &nbsp;and&nbsp;
      <a
        className="underline"
        href={PRIVACY_PATH}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </p>
  );
}
