import React from 'react';
import PropTypes from 'prop-types';

export default function QuizProgressBar({
  stepCount,
  currentStep,
  onNextClick,
  onPreviousClick,
  onDoneClick,
  nextStepText,
  isNextDisabled
}) {
  const isLastStep = currentStep === stepCount;

  return (
    <div className="QuizProgressBar">
      <div className="bg-white br2 box-shadow-strong relative br--top pl4 pb2 pr2 pt2 flex align-items-center">
        <div className="flex-auto pr4">
          <div className="pt2 mb2 relative ba br3 b--light-gray">
            <div
              className="bg-light-gray absolute left-0 top-0 bottom-0 transition"
              style={{ width: `${(currentStep / (stepCount + 1)) * 100}%` }}
            />
          </div>
          <p className="mid-gray i f2">
            Step {currentStep} of {stepCount}
          </p>
        </div>
        <button
          data-test-id="quiz_back"
          disabled={currentStep === 1}
          type="button"
          onClick={onPreviousClick}
          className={`button ph4 ttu mr2 button--${
            currentStep === 1 ? 'disabled' : 'primary'
          }`}
        >
          Back
        </button>
        <button
          data-test-id="quiz_next"
          disabled={isNextDisabled}
          type="button"
          onClick={isLastStep ? onDoneClick : onNextClick}
          className={`button ph4 ttu button--${
            isNextDisabled ? 'disabled' : 'primary'
          }`}
        >
          {isLastStep ? 'Done' : nextStepText}
        </button>
      </div>
    </div>
  );
}

QuizProgressBar.propTypes = {
  stepCount: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  nextStepText: PropTypes.string,
  isNextDisabled: PropTypes.bool.isRequired
};

QuizProgressBar.defaultProps = {
  nextStepText: 'Next'
};
