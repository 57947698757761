import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA, debounce } from 'dpl/shared/utils';
import { get } from 'dpl/shared/utils/object';
import withIndex from 'dpl/decorators/withIndex';
import FullPageQuizContext from 'dpl/components/FullPageQuiz/FullPageQuizContext';
import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';
import { QuizContent } from 'dpl/components/Quiz';
import FullPageQuizHeader from 'dpl/components/FullPageQuiz/FullPageQuizHeader';
import FullPageQuizProgressBar from 'dpl/components/FullPageQuiz/FullPageQuizProgressBar';
import FullPageQuizCompactProgressBar from 'dpl/components/FullPageQuiz/FullPageQuizCompactProgressBar';
import FullPageQuizMobileNavigation from 'dpl/components/FullPageQuiz/FullPageQuizMobileNavigation';

const isMobile = isMobileUA();

export const PROGRESS_BAR_VARIANTS = {
  COMPACT: 'compact',
  REGULAR: 'regular'
};

class FullPageQuiz extends Component {
  static propTypes = {
    decrement: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formErrors: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formState: PropTypes.object.isRequired,
    handleFormFieldChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    increment: PropTypes.func.isRequired,
    isFormSubmitting: PropTypes.bool.isRequired,
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onSkipClick: PropTypes.func,
    onSaveAndExitClick: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    orderedSteps: PropTypes.array.isRequired,
    setIndex: PropTypes.func.isRequired,
    onBeforeNext: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onStepShown: PropTypes.func,
    fixedNav: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    closeButtonLabel: PropTypes.string,
    headerContent: PropTypes.node,
    progressBarVariant: PropTypes.oneOf(Object.values(PROGRESS_BAR_VARIANTS)),
    newDesignSystemStyles: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    closeButtonLabel: 'Save & Exit',
    onStepShown: () => {},
    onPreviousClick: () => {},
    onNextClick: () => {},
    onSkipClick: () => {},
    fixedNav: false,
    onBeforeNext: null,
    isLoading: false,
    headerContent: null,
    progressBarVariant: PROGRESS_BAR_VARIANTS.REGULAR,
    newDesignSystemStyles: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.index !== prevState.lastIndex) {
      return {
        lastIndex: nextProps.index,
        isTransitioning: prevState.lastIndex !== null
      };
    }

    return null;
  }

  constructor(...args) {
    super(...args);

    this.setHistoryIndex(this.props.index);
  }

  state = {
    isTransitioning: false,
    /* eslint-disable-next-line react/no-unused-state */
    lastIndex: null,
    contentHeight: null
  };

  quizContentRef = createRef();
  currentStepRef = createRef();

  focusFirstInputIfNeeded = () => {
    // HACK: FullPageQuizQuestion could probably handle this logic via props,
    // but we have to know about transition state here before focusing
    if (
      get(
        this.props.orderedSteps[this.props.index],
        'otherProps.disableAutofocus'
      )
    ) {
      return;
    }

    if (
      !isMobile &&
      !this.state.isTransitioning &&
      this.currentStepRef.current
    ) {
      const firstInputEl = this.currentStepRef.current.querySelector(
        'input,textarea,select'
      );
      if (firstInputEl) {
        firstInputEl.focus();
      }
    }
  };

  handleNextClick = async () => {
    if (!CONFIG.isTest && this.state.isTransitioning) {
      return;
    }

    const { increment, onBeforeNext, onNextClick } = this.props;

    if (onBeforeNext) {
      await onBeforeNext();
    }

    increment(() => {
      this.setHistoryIndex(this.props.index);
      onNextClick(this.props.index);
    });
  };

  handleSkipClick = async () => {
    if (!CONFIG.isTest && this.state.isTransitioning) {
      return;
    }

    const { increment, onSkipClick } = this.props;

    increment(() => {
      this.setHistoryIndex(this.props.index);
      onSkipClick(this.props.index);
    });
  };

  handlePreviousClick = () => {
    if (!CONFIG.isTest && this.state.isTransitioning) {
      return;
    }

    this.props.decrement(() => this.setHistoryIndex(this.props.index));
    this.props.onPreviousClick();
  };

  handleTransitionEnded = () => {
    this.setState({ isTransitioning: false });
  };

  handleHistoryChange = e => {
    if (!e.state) {
      return;
    }

    const index = Number(e.state.currentStepIdx);

    if (!Number.isNaN(index)) {
      this.props.setIndex(index);
    }
  };

  setHistoryIndex = idx => {
    window.history.pushState({ currentStepIdx: idx }, '');
  };

  setContentHeight = () => {
    if (this.quizContentRef.current) {
      this.setState({
        contentHeight: `${this.quizContentRef.current.clientHeight}px`
      });
    }
  };

  debouncedSetContentHeight = debounce(this.setContentHeight, 300);

  componentDidMount() {
    this.focusFirstInputIfNeeded();
    window.addEventListener('popstate', this.handleHistoryChange);
    window.addEventListener('resize', this.debouncedSetContentHeight);
    this.setContentHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isTransitioning !== this.state.isTransitioning) {
      this.focusFirstInputIfNeeded();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryChange);
    window.removeEventListener('resize', this.debouncedSetContentHeight);
  }

  render() {
    const {
      className,
      closeButtonLabel,
      formErrors,
      formState,
      handleFormFieldChange,
      index,
      isFormSubmitting,
      onSaveAndExitClick,
      orderedSteps,
      onStepShown,
      onSubmit,
      fixedNav,
      onBeforeNext,
      isLoading,
      headerContent,
      progressBarVariant,
      newDesignSystemStyles
    } = this.props;

    const { contentHeight } = this.state;

    const currentStep = orderedSteps[index];
    const isCurrentStepInvalid =
      Boolean(currentStep) && currentStep.isValid === false;

    return (
      <div
        className={classnames('FullPageQuiz overflow-hidden', {
          DSFullPageQuiz: newDesignSystemStyles
        })}
        data-step-idx={index}
      >
        <FullPageQuizContext.Provider value={{ fixedNav, isLoading }}>
          <div
            // TODO: temporarily forcing no-outline until we have designs for a subtler one
            className={classnames(
              'absolute--fill fixed flex flex-column justify-center items-center no-outline',
              className,
              {
                'bg-sand': newDesignSystemStyles
              }
            )}
          >
            <div className="w-100">
              <FullPageQuizHeader
                onSaveClick={onSaveAndExitClick}
                onBackClick={index > 0 ? this.handlePreviousClick : null}
                closeButtonLabel={closeButtonLabel}
              >
                {headerContent}
              </FullPageQuizHeader>
            </div>
            <div ref={this.quizContentRef} className="flex-auto w-100">
              <QuizContent
                currentQuestionIdx={index}
                onTransitionEnd={this.handleTransitionEnded}
                questionClassName="FullPageQuiz__quizQuestion"
                isTransitioning={this.state.isTransitioning}
              >
                {orderedSteps.map((q, idx) => {
                  const isShown = idx === index;
                  return (
                    <div
                      key={idx}
                      ref={isShown && this.currentStepRef}
                      style={{ height: contentHeight }}
                    >
                      <FixedScrollWrapper className="overflow-x-hidden h-100">
                        <div className="flex items-center justify-center min-h-100">
                          <div
                            className={classnames(
                              'w-100 FullPageQuiz__questionContainer',
                              {
                                'FullPageQuiz__questionContainer--shown':
                                  isShown
                              }
                            )}
                          >
                            <q.Component
                              onChange={q.onChange || handleFormFieldChange}
                              handleNextClick={this.handleNextClick}
                              handleSkipClick={this.handleSkipClick}
                              formErrors={formErrors}
                              formState={formState}
                              isFormSubmitting={isFormSubmitting}
                              isValid={q.isValid !== false}
                              isShown={isShown}
                              onShown={onStepShown}
                              stepIndex={index}
                              {...q.otherProps}
                            />
                          </div>
                        </div>
                      </FixedScrollWrapper>
                    </div>
                  );
                })}
              </QuizContent>
            </div>
            <footer
              className={classnames('w-100 f0', {
                'box-shadow-2': fixedNav
              })}
            >
              {fixedNav && (
                <FullPageQuizMobileNavigation
                  onPreviousClick={this.handlePreviousClick}
                  onNextClick={this.handleNextClick}
                  onSubmit={onSubmit}
                  isSubmitDisabled={isFormSubmitting || isCurrentStepInvalid}
                  isLastStep={index === orderedSteps.length - 1}
                  isPreviousDisabled={index === 0}
                  isNextDisabled={isCurrentStepInvalid || isLoading}
                  onBeforeNext={onBeforeNext}
                />
              )}
              {progressBarVariant === PROGRESS_BAR_VARIANTS.COMPACT ? (
                <FullPageQuizCompactProgressBar
                  currentStepIndex={index}
                  totalStepCount={orderedSteps.length}
                  isTransitioning={this.state.isTransitioning}
                />
              ) : (
                <FullPageQuizProgressBar
                  currentStep={index}
                  steps={orderedSteps}
                  progressBarColor={newDesignSystemStyles && 'royal-blue'}
                />
              )}
            </footer>
          </div>
        </FullPageQuizContext.Provider>
      </div>
    );
  }
}

export default withIndex(FullPageQuiz);
