import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeSequence } from 'dpl/shared/utils';

const STEP_WIDTH = 16;

export default function FullPageQuizCompactProgressBar({
  currentStepIndex,
  totalStepCount,
  isTransitioning
}) {
  const [currentStepNumber, setCurrentStepNumber] = useState(
    currentStepIndex + 1
  );

  useEffect(() => {
    if (!isTransitioning) {
      setCurrentStepNumber(currentStepIndex + 1);
    }
  }, [isTransitioning, currentStepIndex]);

  return (
    <div className="f2 dark-gray tc pv4 ph4">
      <ul
        className="flex flex-wrap justify-center mb2 center mw-100"
        style={{ gap: STEP_WIDTH / 8 }}
      >
        {makeSequence(totalStepCount).map(stepIndex => (
          <li
            key={stepIndex}
            className={classnames(
              'w-100 transition',
              currentStepNumber > stepIndex ? 'bg-green' : 'bg-light-gray',
              stepIndex === 0 && 'br-pill br--top-right br--bottom-right',
              stepIndex === totalStepCount - 1 &&
                'br-pill br--top-left br--bottom-left'
            )}
            style={{ width: STEP_WIDTH, height: STEP_WIDTH / 4 }}
          />
        ))}
      </ul>
      <p>
        Step {currentStepNumber} of {totalStepCount}
      </p>
    </div>
  );
}

FullPageQuizCompactProgressBar.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  totalStepCount: PropTypes.number.isRequired,
  isTransitioning: PropTypes.bool.isRequired
};
